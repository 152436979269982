import { SimpleDialog } from "@/common/SimpleDialog";
import { appDialogNames } from "@/helper/appDialogNames";
import {
    Alert,
    Button,
    Checkbox,
    FormControlLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { css } from "@emotion/css";
import { appStyles } from "@/helper/appStyles";
import { ContainerContractDocument, DriverReportDocument } from "tb-utils";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DeliveryNoteCell } from "@/common/ContainerContractPage/DeliveryNoteCell";
import { useFormatContracts } from "@/common/DrivingJobOverviewPage/useFormatContracts";
import { useDialog } from "@/customHooks/useDialog";
import { DialogActionButton } from "@/common/SimpleDialog/DialogActionButton";
import { useFirestoreWrite } from "@/customHooks/useFirestoreWrite";
import { appLogger } from "@/helper/appLogger";
import { useSigninCheck } from "reactfire";
import DoneIcon from '@mui/icons-material/Done';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ReactSignatureCanvas from "react-signature-canvas";
import { v4 as uuid } from "uuid";
import { startOfDay } from "date-fns";
import { serverTimestamp } from "firebase/firestore";
import { useFirebaseStorage } from "@/customHooks/useFirebaseStorage";
import { useQueryParam } from "@/customHooks/useQueryParam";
import { DriverReportForm } from "@/common/DrivingJobOverviewPage/DriverReportForm";

const FILE_NAME = "CreateDriverReportDialog.tsx";

const tableStyles = {
    drivingJobId: css({
        display: "flex",
        // flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 5,
        width: "100%"
    }),
};

interface Props {
    selectedDay: Date;
    driverReportData: DriverReportDocument<Date> | null;
    /** Set to properly open the finalize delivery-note dialog */
    setFinalizeContract: Dispatch<SetStateAction<ContainerContractDocument<Date> | null>>;
}

export function CreateDriverReportDialog(props: Props) {
    const { data: userData } = useSigninCheck();
    const { createDriverReport, deleteDriverReport } = useFirestoreWrite();
    const { contracts, isContractsLoading, sortOrder } = useFormatContracts(props.selectedDay, true, true);
    const { getOpenDialog, closeOpenDialog } = useDialog();
    const { setMultipleQueryParams } = useQueryParam("");

    const [isDepartureCheckDone, setIsDepartureCheckDone] = useState(false);
    const [isCreationLoading, setIsCreationLoading] = useState(false);
    const [isDeletionLoading, setIsDeletionLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const padRef = React.useRef<ReactSignatureCanvas>(null);
    const [isPadEdited, setIsPadEdited] = useState(false);
    const [padResult, setPadResult] = useState("");
    const [showMissingFinalizeData, setShowMissingFinalizeData] = useState(false);

    const [licensePlate, setLicensePlate] = useState("");
    const [licensePlateTrailer, setLicensePlateTrailer] = useState("");
    const [startTime, setStartTime] = useState<Date | undefined | null>(null);
    const [endTime, setEndTime] = useState<Date | undefined | null>(null);
    const [breakLength, setBreakLength] = useState("");

    const { uploadDrivingJobSignature, deleteDrivingJobSignature } = useFirebaseStorage();

    const styles = {
        dialog: css({
            display: "flex",
            flexDirection: "column",
            gap: 20
        }),
        signFieldWrapper: css({
            display: "flex",
            flexDirection: "column",
            gap: 5
        }),
        deleteSignatureWrapper: css({
            display: "flex",
            justifyContent: "flex-end",
            opacity: isPadEdited ? 1 : 0,
            pointerEvents: isPadEdited ? "auto" : "none",
        }),
        signField: css({
            height: 120,
            border: "1px solid #ccc",
            borderRadius: appStyles.borderRadius.smaller
        })
    };

    /** Reset state on close */
    useEffect(() => {
        if (!getOpenDialog) {
            setIsDepartureCheckDone(false);
            setIsPadEdited(false);
        }
    }, [getOpenDialog]);

    const isReviewed = props?.driverReportData?.review_status === 1;

    const updateIsPadEditedState = () => {
        setIsPadEdited(!padRef.current?.isEmpty() || false);
    };

    const handlePadOnBegin = () => {
        updateIsPadEditedState();
    };

    const handlePadOnEnd = () => {
        updateIsPadEditedState();
        setPadResult(
            padRef.current?.getTrimmedCanvas().toDataURL("image/png") || ""
        );
    };

    /** Check if any contract has finalize-data set, if not set showMissingFinalizeData to true */
    useEffect(() => {
        const missingLength = contracts
            .filter(c => !(c.type === 2 && c.id?.endsWith("/1"))) // remove c1 (from `place and fetch`) contracts
            .filter(c => !c.finalize_data_changed_by)
            .length;
        setShowMissingFinalizeData(!!missingLength);
    }, [contracts]);

    const createActionButtons: DialogActionButton[] = [
        {
            text: "Abbrechen",
            closeDialogOnClick: true,
        },
        {
            text: props.driverReportData ?
                "Bericht Status" :
                "Bericht einreichen",
            disabled: (
                !isDepartureCheckDone ||
                !contracts.length ||
                !isPadEdited ||
                showMissingFinalizeData
            ),
            onClick: async () => {
                setErrorMessage("");

                /** Exit function if uid is undefined */
                if (!userData.user?.uid) {
                    appLogger.error(FILE_NAME, "Error creating driver report");
                    setErrorMessage("Fehler beim erstellen (UID nicht gesetzt)");
                    return;
                }

                setIsCreationLoading(true);

                /** Upload signature image */
                const signatureImageId = uuid();
                try {
                    await uploadDrivingJobSignature(signatureImageId, padResult);
                    appLogger.info(FILE_NAME, "Uploaded signature");
                } catch (err) {
                    appLogger.error(FILE_NAME, "Error uploading image " + err);
                    setErrorMessage("Fehler beim Speichern der Unterschrift.");
                    setIsCreationLoading(false);
                    return;
                }

                /** Create driver report in firestore */
                createDriverReport({
                    delivery_notes: contracts.map(c => (c.id as string)),
                    driver: userData.user.uid,

                    license_plate: licensePlate,
                    license_plate_trailer: licensePlateTrailer,
                    start_time: startTime || null,
                    end_time: endTime || null,
                    break_length: breakLength,

                    day: startOfDay(props.selectedDay),
                    signature_image_id: signatureImageId,
                    reviewed_by: null,
                    reviewed_at: null,
                    review_status: 0,
                    created_at: serverTimestamp(),
                })
                    .then(() => {
                        appLogger.event(FILE_NAME, "Created driver report");
                        closeOpenDialog();
                    })
                    .catch(err => {
                        appLogger.error(FILE_NAME, "Error creating driver report:" + err);
                        setErrorMessage("Fehler beim Speichern des Berichts");
                    })
                    .finally(() => {
                        setIsCreationLoading(false);
                    });
            }
        },
    ];

    const doneActionButtons: DialogActionButton[] = [
        {
            text: "Bericht zurücknehmen",
            color: "error",
            disabled: isReviewed,
            onClick: async () => {
                setErrorMessage("");

                /** Exit function if uid is undefined */
                if (!props?.driverReportData?.doc_id) {
                    appLogger.error(FILE_NAME, "Error deleting own driver report. doc_id not set.");
                    setErrorMessage("Fehler. Der Bericht konnte nicht gelöscht werden.");
                    return;
                }

                setIsDeletionLoading(true);

                /** Delete signature image */
                try {
                    await deleteDrivingJobSignature(props.driverReportData.signature_image_id)
                } catch (err) {
                    const errorCode = (err as any).code;
                    if (errorCode !== "storage/object-not-found") {
                        setErrorMessage("Fehler beim Löschen (Signatur konnte nicht gelöscht werden)");
                        appLogger.error(FILE_NAME, "Error deleting signature image" + err);
                        return;
                    }
                    appLogger.info(FILE_NAME, "Signature image not found. Continue.");
                }

                /** Delete driver report doc */
                deleteDriverReport(props.driverReportData.doc_id)
                    .then(() => {
                        closeOpenDialog();
                    })
                    .catch(() => {
                        appLogger.error(FILE_NAME, "Error deleting own driver report.");
                        setErrorMessage("Fehler. Der Bericht konnte nicht gelöscht werden.");
                    })
                    .finally(() => {
                        setIsDeletionLoading(false);
                        setErrorMessage("");
                    });

            },
        },
        {
            text: "Schließen",
            closeDialogOnClick: true,
        },
    ];

    const columns: GridColDef[] = [
        ...showMissingFinalizeData ? [{
            field: 'missingFinalize',
            headerName: '',
            sortable: false,
            width: 130,
            renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => {
                const isC1 = (params.id as string).endsWith(("/1"));
                const isStellenContract = params.row.type === 2 && isC1;
                const showFinalizeButton = !isStellenContract && !params.row.finalize_data_changed_by;
                return showFinalizeButton ?
                    <Button
                        color="error"
                        size="small"
                        onClick={() => {
                            props.setFinalizeContract(params.row);
                            setMultipleQueryParams({
                                "dialog": appDialogNames.container.finalizeDeliveryNote,
                                "from-driver-report-dialog": "1",
                            })
                        }}
                    >
                        Daten angeben
                    </Button>
                    :
                    null;
            }
        }] : [],
        {
            field: 'drivingJobId',
            headerName: 'Auftrag',
            sortable: false,
            flex: 1,
            minWidth: 100,
            maxWidth: 140,
            renderCell: (params: GridRenderCellParams<any, ContainerContractDocument<Date> & Record<string, any>>) => {
                const isC1 = (params.id as string).endsWith(("/1"));
                return <div className={tableStyles.drivingJobId}>
                    <DeliveryNoteCell
                        isC1={isC1}
                        contractData={params.row}
                        hideDate
                    />
                    {/*<div>*/}
                    {/*    <Button color="warning" size="small">*/}
                    {/*        Abschließen*/}
                    {/*    </Button>*/}
                    {/*    /!*<Chip label="asd"/>*!/*/}
                    {/*</div>*/}
                </div>;
            }
        },
        {
            field: 'c1_da_recipient',
            headerName: 'Kunde',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
    ];

    return <SimpleDialog
        className={styles.dialog}
        name={appDialogNames.container.createDriverReport}
        title={props.driverReportData ?
            "Bericht" :
            "Bericht erstellen"}
        actionButtons={props.driverReportData ? doneActionButtons : createActionButtons}
        disableBackdropClick={(
            isDepartureCheckDone ||
            isPadEdited
        )}
        fullscreenLoading={{ loading: isCreationLoading }}
    >

        {/*<img*/}
        {/*    src={imageUrl}*/}
        {/*    alt={"Image from firebase storage not found"}*/}
        {/*    style={{ maxWidth: "100%", maxHeight: 200 }}*/}
        {/*/>*/}
        {/*<div>*/}
        {/*    {imageStatus}*/}
        {/*</div>*/}

        {props.driverReportData ?
            <List dense>
                <ListItem>
                    <ListItemIcon>
                        <DoneIcon color="secondary"/>
                    </ListItemIcon>
                    <ListItemText primary="Du hast den Bericht eingereicht."/>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        {isReviewed ?
                            <DoneIcon color="secondary"/> :
                            <HourglassTopIcon/>
                        }
                    </ListItemIcon>
                    <ListItemText primary={isReviewed ?
                        "Der Bericht wurde genehmigt." :
                        "Der Bericht wurde noch nicht genehmigt."
                    }/>
                </ListItem>
            </List>
            :
            <>
                <DataGrid
                    columns={columns}
                    rows={contracts}
                    hideFooter
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    autoHeight
                    density="compact"
                    loading={isContractsLoading}
                />

                {/*<LicensePlate/>*/}

                <div className="h-1"/>

                <DriverReportForm
                    licensePlate={licensePlate}
                    setLicensePlate={setLicensePlate}
                    licensePlateTrailer={licensePlateTrailer}
                    setLicensePlateTrailer={setLicensePlateTrailer}
                    startTime={startTime}
                    setStartTime={setStartTime}
                    endTime={endTime}
                    setEndTime={setEndTime}
                    breakLength={breakLength}
                    setBreakLength={setBreakLength}
                />

                <FormControlLabel
                    control={<Checkbox
                        checked={isDepartureCheckDone}
                        onChange={e => setIsDepartureCheckDone(e.target.checked)}
                    />}
                    label={<Typography variant="body2">Abfahrtskontrolle erledigt</Typography>}
                />

                <div className={styles.signFieldWrapper}>
                    <div className={styles.deleteSignatureWrapper}>
                        <Button
                            size="small"
                            variant="text"
                            color="inherit"
                            onClick={() => {
                                padRef.current?.clear();
                                updateIsPadEditedState();
                            }}
                        >
                            Unterschrift löschen
                        </Button>
                    </div>

                    <ReactSignatureCanvas
                        onBegin={handlePadOnBegin}
                        onEnd={handlePadOnEnd}
                        ref={padRef}
                        canvasProps={{
                            className: styles.signField,
                        }}
                    />
                    <Typography variant="body2">
                        Unterschrift des Fahrers
                    </Typography>
                </div>
            </>
        }

        {!!errorMessage &&
            <Alert
                icon={false}
                color="error"
                onClose={() => {
                    setErrorMessage("");
                }}
            >
                {errorMessage}
            </Alert>
        }

    </SimpleDialog>;
}

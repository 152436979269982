import { css } from "@emotion/css";
import { DesktopMainLeftNavItem } from "./DesktopMainLeftNavItem";
import { useLocation } from "react-router-dom";
import { appPaths } from "@/helper/appPaths";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import GroupIcon from "@mui/icons-material/Group";
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useCompanyStore } from "@/stores/useCompanyStore";
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { usePermission } from "@/customHooks/usePermission";
import ApartmentIcon from '@mui/icons-material/Apartment';
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const styles = {
    main: css({
        // border: "1px solid red",
        width: 300,
        padding: "30px 0"
    }),
    content: css({
        // border: "1px solid red",
    })
}

export function DesktopMainLeftNav() {
    const { pathname } = useLocation();
    const { hasPerm } = usePermission();

    const companyData = useCompanyStore(state => state.companyData);
    const companyModules = companyData.data?.modules;

    return <div className={styles.main}>
        <div className={styles.content}>
            {companyModules?.includes("TIME_BOOKING_MODULE") && <>
                <DesktopMainLeftNavItem
                    text="Zeiten"
                    linkTo={appPaths.timeBooking}
                    icon={<AccessTimeOutlinedIcon/>}
                    activeIcon={<AccessTimeFilledIcon/>}
                    active={pathname.startsWith(appPaths.timeBooking)}
                />

                <DesktopMainLeftNavItem
                    text="Urlaub"
                    linkTo={appPaths.vacation}
                    icon={<WbSunnyOutlinedIcon/>}
                    activeIcon={<WbSunnyIcon/>}
                    active={pathname.startsWith(appPaths.vacation)}
                />
            </>}

            {companyModules?.includes("CONTAINER_MODULE") && <>
                <DesktopMainLeftNavItem
                    text="Fahrten"
                    linkTo={appPaths.drivingJobs}
                    icon={<LocalShippingOutlinedIcon/>}
                    activeIcon={<LocalShippingIcon/>}
                    active={pathname.startsWith(appPaths.drivingJobs)}
                />
                {hasPerm("DRIVER_REPORTS_VIEW") &&
                    <DesktopMainLeftNavItem
                        text="Berichte"
                        linkTo={appPaths.driverReports}
                        icon={<FactCheckOutlinedIcon/>}
                        activeIcon={<FactCheckIcon/>}
                        active={pathname.startsWith(appPaths.driverReports)}
                    />
                }
                {hasPerm("CONTAINER_CONTRACTS_VIEW") &&
                    <DesktopMainLeftNavItem
                        text="Aufträge"
                        linkTo={appPaths.containerContract.overview}
                        icon={<FolderOpenOutlinedIcon/>}
                        activeIcon={<FolderIcon/>}
                        active={(
                            pathname.startsWith(appPaths.containerContract.overview) ||
                            pathname.startsWith(appPaths.deliveryNote)
                        )}
                    />
                }
                {/*<DesktopMainLeftNavItem*/}
                {/*    text="Container Standort"*/}
                {/*    linkTo={appPaths.containerLocation}*/}
                {/*    icon={<LocationOnOutlinedIcon/>}*/}
                {/*    activeIcon={<LocationOnIcon/>}*/}
                {/*    active={pathname.startsWith(appPaths.containerLocation)}*/}
                {/*/>*/}
            </>}

            {companyModules?.includes("NOTES_MODULE") &&
                <DesktopMainLeftNavItem
                    text="Notizen"
                    linkTo={appPaths.notes}
                    icon={<AssignmentOutlinedIcon/>}
                    activeIcon={<AssignmentIcon/>}
                    active={pathname.startsWith(appPaths.notes)}
                />
            }

            {companyModules?.includes("CUSTOMER_FILE") &&
                <DesktopMainLeftNavItem
                    text="Kunden"
                    linkTo={appPaths.cf.main}
                    icon={<GroupOutlinedIcon/>}
                    activeIcon={<GroupIcon/>}
                    active={pathname.startsWith(appPaths.cf.main)}
                />
            }

            {companyModules?.includes("CALENDAR_TO_INCOME_STATEMENT") &&
                <DesktopMainLeftNavItem
                    text="Umsätze"
                    linkTo={appPaths.cis.main}
                    icon={<TrendingUpIcon/>}
                    activeIcon={<TrendingUpIcon/>}
                    active={pathname.startsWith(appPaths.cis.main)}
                />
            }

            {companyModules?.includes("ADMIN_MODULE") &&
                <DesktopMainLeftNavItem
                    text="Übersicht"
                    linkTo={appPaths.admin.main}
                    icon={<ApartmentIcon/>}
                    active={pathname.startsWith(appPaths.admin.main)}
                />
            }

            {hasPerm("ADMIN") &&
                <DesktopMainLeftNavItem
                    text="Firma"
                    linkTo={appPaths.companyGeneral}
                    icon={<SettingsOutlinedIcon/>}
                    activeIcon={<SettingsIcon/>}
                    active={pathname.startsWith(appPaths.companySettings)}
                />
            }
        </div>
    </div>;
}

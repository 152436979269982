/**
 * https://github.com/firebase/firebase-js-sdk/issues/519#issuecomment-366509360
 * https://stackoverflow.com/questions/39914899/using-firebase-auth-to-access-the-google-calendar-api
 *
 * calendar api example: https://developers.google.com/calendar/api/quickstart/js#set_up_the_sample
 * Kassenbuch Vorlage: https://excelnova.org/wp-content/uploads/2014/04/image27.png
 */
import { getAuth, GoogleAuthProvider, linkWithPopup, signOut, signInWithPopup } from "firebase/auth";
import { useSigninCheck } from "reactfire";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { endOfDay, parse, startOfDay, subDays } from "date-fns";
import { css } from "@emotion/css";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import format from "date-fns/format";
import GoogleIcon from '@mui/icons-material/Google';
import LogoutIcon from '@mui/icons-material/Logout';

const styles = {
    content: css({
        display: "flex",
        flexDirection: "column",
        gap: 16,
        padding: 20,
        alignItems: "flex-start"
    }),
    nav: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
    }),
    events: css({
        // border: "1px solid blue",
        width: "100%",
        paddingBottom: 50
    }),
    eventItem: css({
        border: "1px solid red"
    }),
};

// export function CISMainPage() {
//     return <div>
//         CisMainPage
//         <br/>
//         <br/>
//         Add an description to explain what it does and how to use it.
//     </div>;
// }

export function CISMainPage() {
    const { data: userData } = useSigninCheck();

    useEffect(() => {
        console.log("userData", userData);
    }, [userData]);

    const [accessTkn, setAccessTkn] = useState("");
    const [allCalendars, setAllCalendars] = useState<any>(null);
    const [calendarData, setCalendarData] = useState<any>(null);

    const [selectedCalendar, setSelectedCalendar] = useState("");
    const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 30));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [displayError, setDisplayError] = useState("");
    // const [devAccessTkn, setDevAccessTkn] = useState("");
    // const [devApiUrl, setDevApiUrl] = useState(
    //     // "https://www.googleapis.com/calendar/v3/calendars/primary/events?maxResults=20"
    //     "https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=2024-01-03T10:00:00Z&timeMax=2024-01-16T10:00:00Z"
    // );

    const getAllCalendars = () => {
        const url = "https://www.googleapis.com/calendar/v3/users/me/calendarList";
        fetch(url, {
            headers: { "Authorization": `Bearer ${accessTkn}` }
        })
            .then(res => res.json())
            .then(data => {
                setAllCalendars(data);
            })
            .catch(err => {
                const msg = "Die Kalender konnten nicht abgerufen werden";
                setDisplayError(msg);
                console.error(msg, err);
            });
    };

    const getCalendarEvents = (calId: string) => {
        // const calId = "c7fb666022305dfa421e5026f983950223345c68ae8f422753d901c91e20b3ec@group.calendar.google.com";
        const listUrl = `https://www.googleapis.com/calendar/v3/calendars/${calId}/events`;
        const search = new URLSearchParams({
            maxResults: "5000",
            // orderBy: "startTime", // Does not work with current query
            timeMin: startOfDay(startDate).toISOString(),
            timeMax: endOfDay(endDate).toISOString(),
        });
        fetch(`${listUrl}?${search.toString()}`, {
            headers: { "Authorization": `Bearer ${accessTkn}` }
        })
            .then(res => res.json())
            .then(data => {
                setCalendarData(data);
            })
            .catch(err => {
                const msg = "Die Termine konnten nicht abgerufen werden";
                setDisplayError(msg);
                console.error(msg, err);
            });
    };

    const linkWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        console.log("get scopes 1", provider.getScopes());
        provider.addScope("https://www.googleapis.com/auth/calendar.readonly");
        provider.addScope("https://www.googleapis.com/auth/calendar.events.readonly");
        console.log("get scopes 2", provider.getScopes());

        const auth = getAuth();
        if (auth.currentUser) {
            linkWithPopup(auth.currentUser, provider)
                .then(result => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    setAccessTkn(credential?.accessToken || "");
                    console.log("LINK WITH GOOGLE SUCCESS", result.user, credential, credential?.accessToken);
                })
                .catch((error) => {
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    console.log("LINK WITH GOOGLE ERROR", error.code, error.message, error.customData.email, credential);
                });
        } else {
            console.error("(LINK WITH GOOGLE) AUTH is null"); // TODO: add error message in ui
        }
    };

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        console.log("get scopes 1", provider.getScopes());
        provider.addScope("https://www.googleapis.com/auth/calendar.readonly");
        provider.addScope("https://www.googleapis.com/auth/calendar.events.readonly");
        console.log("get scopes 2", provider.getScopes());

        signInWithPopup(getAuth(), provider)
            .then(result => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                setAccessTkn(credential?.accessToken || "");
                console.log("SUCCESS", result.user, credential, credential?.accessToken);
            })
            .catch((error) => {
                const credential = GoogleAuthProvider.credentialFromError(error);
                console.log("ERROR", error.code, error.message, error.customData.email, credential);
            });
    };

    const googleSignOut = () => {
        signOut(getAuth())
            .then(() => setAccessTkn(""))
            .catch(console.log);
    };

    function findPriceInString(input: string): {
        value: string,
        isNegative: boolean
    } | null {
        const findPrices = input.match(/-?\d+(,\d{1,2})?€/g);
        if (!findPrices?.length) return null;
        const price = findPrices[0];
        return { value: price, isNegative: price.startsWith("-") };
    }

    const columns: GridColDef[] = [
        {
            field: "date",
            headerName: "Datum",
            sortable: false,
            editable: false,
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                console.log("params.row.start.date", params.row.start.date);
                const startDate = parse(params.row.start.date, "yyyy-MM-dd", new Date());
                console.log("startDate", startDate);
                return format(startDate, "dd.MM.yyyy");
            }
        },
        {
            field: "customer_name",
            headerName: "Kunde",
            sortable: false,
            editable: true,
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return "Kunde";
            }
        },
        {
            field: "revenue",
            headerName: "Einnahmen",
            sortable: false,
            editable: true,
            width: 110,
            renderCell: (params: GridRenderCellParams) => {
                const price = findPriceInString(params.row.summary);
                if (!price) return "";
                if (price.isNegative) return "";
                return price.value;
            }
        },
        {
            field: "expenditure",
            headerName: "Ausgaben",
            sortable: false,
            editable: true,
            width: 110,
            renderCell: (params: GridRenderCellParams) => {
                const price = findPriceInString(params.row.summary);
                if (!price) return "";
                if (!price.isNegative) return "";
                return price.value.substring(1);
            }
        },
        {
            field: "info",
            headerName: "Anmerkung",
            sortable: false,
            editable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                const price = findPriceInString(params.row.summary);
                return <>
                    {!price &&
                        <Typography variant="body2" fontWeight={500} color="error">Keinen Preis gefunden</Typography>}
                </>;
            }
        },
    ];

    useEffect(() => {
        if (accessTkn) getAllCalendars();
    }, [accessTkn]);

    useEffect(() => {
        if (selectedCalendar) getCalendarEvents(selectedCalendar);
    }, [selectedCalendar, startDate, endDate]);

    return <div>
        <div className={styles.content}>
            {displayError &&
                <Alert color="error" icon={false} onClose={() => setDisplayError("")}>
                    {displayError}
                </Alert>
            }

            {!accessTkn ?
                <div>
                    <Button startIcon={<GoogleIcon/>} variant="outlined" onClick={linkWithGoogle}>
                        Mit Google verlinken
                    </Button>
                    <Button startIcon={<GoogleIcon/>} variant="contained" onClick={signInWithGoogle}>
                        Mit Google anmelden
                    </Button>
                </div>
                :
                <>
                    {/** First Nav*/}
                    <div className={styles.nav}>
                        <Button startIcon={<LogoutIcon/>} size="small" onClick={googleSignOut}>
                            {userData?.user?.email} Abmelden
                        </Button>
                    </div>

                    {/** Second Nav*/}
                    <div className={styles.nav}>
                        <FormControl sx={{ minWidth: "200px" }}>
                            <InputLabel id="demo-simple-select-label">Kalender auswählen</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedCalendar}
                                label="Kalender auswählen"
                                onChange={event => {
                                    setSelectedCalendar(event.target.value);
                                }}
                            >
                                {allCalendars?.items?.map((cal: any) => (
                                    <MenuItem key={cal.id} value={cal.id}>
                                        {cal.summary}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {!!selectedCalendar && <>
                            <DatePicker
                                renderInput={e => <TextField {...e}/>}
                                label="Start"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue || subDays(new Date(), 30))}
                            />
                            <DatePicker
                                renderInput={e => <TextField {...e}/>}
                                label="Ende"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue || new Date())}
                            />
                        </>}
                    </div>

                    <div className={styles.events}>
                        {!!selectedCalendar && <>
                            <DataGrid
                                rows={calendarData?.items || []}
                                columns={columns}
                                checkboxSelection
                                autoHeight

                                hideFooter
                                disableSelectionOnClick
                                disableColumnFilter
                                disableColumnSelector
                                disableColumnMenu
                                density="compact"
                            />
                        </>}
                    </div>
                </>
            }
        </div>
    </div>;
}

/** Module roots paths */
export const rootPaths = {
    customerFile: "/customer-file",
    admin: "/admin",
};

export const appPaths = {
    root: '/',
    login: "/login",
    register: "/register",

    /** Irina pages */
    tax: {
        main: "/tax"
    },

    setupPages: {
        mainPage: '/setup',
        createOrJoinCompany: "/setup/company",
        createCompany: "/setup/company/:creationToken",
        companyCreated: "/setup/company-created/:companyId",
        introAfterCompanyCreation: "/setup/company-created",
    },

    /** Beutner pages */
    timeBooking: '/time-booking',
    vacation: '/vacation',
    notes: '/notes',

    /** Peschke pages */
    drivingJobs: '/driving-jobs',
    driverReports: "/driver-reports",
    containerContract: {
        overview: "/container-contracts",
        create: "/container-contracts/create",
        edit: "/container-contracts/:contractId",
    },
    containerLocation: "/container-location",
    deliveryNote: "/delivery-note",

    /** Customer-file pages */
    cf: {
        main: "/customer-file",
        create: `/customer-file/create`,
        customer: `/customer-file/:customerId`,
        edit: (customerId?: string) => `/customer-file/${customerId || ":customerId"}/edit`,
        calendarExport: `/customer-file/calendar-export` // TODO: Maybe /income-statement
    },

    /** Calendar to income statement pages */
    cis: {
        main: "/calendar-to-income-statement",
    },

    /** Admin module */
    admin: {
        main: rootPaths.admin,
    },

    /** Company settings */
    companySettings: '/company',
    companyGeneral: '/company/general',
    companyManageEmployees: '/company/employees',
    companyManageWorkTypes: '/company/work-types',
    companyManageTargetHours: '/company/target-hours',
    companyManagePublicHolidays: '/company/public-holidays',
    companyManageVacationDays: '/company/vacation-days',

    /** Other pages */
    logout: '/logout',
    dev: '/dev',
    devLogger: '/dev-logger',
    devRequests: '/dev-requests',
};

import { useAutoRedirectToSetupPage } from "@/customHooks/useAutoRedirectToSetupPage";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { appPaths } from "@/helper/appPaths";
import { LoadingSpinnerWithBackdrop } from "@/common/LoadingSpinnerWithBackdrop";
import { LoginPage } from "@/common/LoginPage";
import { RegisterPage } from "@/common/RegisterPage";
import { isMobile } from "react-device-detect";
import { SetupVerifyEmail } from "@/common/setupPages/SetupVerifyEmail";
import { SetupCreateOrJoinCompany } from "@/common/setupPages/SetupCreateOrJoinCompany";
import { SetupCreateCompany } from "@/common/setupPages/SetupCreateCompany";
import { SetupCompanyCreatedPage } from "@/common/setupPages/SetupCompanyCreatedPage";
import { MobileTimeBookingPage } from "@/mobile/pages/MobileTimeBookingPage";
import { MobileVacationPage } from "@/mobile/pages/MobileVacationPage";
import { MobileNotesPage } from "@/mobile/pages/MobileNotesPage";
import { DesktopTimeBookingPage } from "@/desktop/pages/DesktopTimeBookingPage";
import { DesktopVacationPage } from "@/desktop/pages/DesktopVacationPage";
import { DesktopNotesPage } from "@/desktop/pages/DesktopNotesPage";
import { DrivingJobOverviewPage } from "@/common/DrivingJobOverviewPage";
import { DriverReportsPage } from "@/common/DriverReportsPage";
import { ContainerContractPage } from "@/common/ContainerContractPage";
import { CreateContainerContractPage } from "@/common/ContainerContractPage/CreateContainerContractPage";
import { EditContainerContractPage } from "@/common/ContainerContractPage/EditContainerContractPage";
import { DeliveryNotePage } from "@/common/DeliveryNotePage";
import { DesktopContainerLocationPage } from "@/desktop/pages/DesktopContainerLocationPage";
import { ModuleCustomerFile } from "@/ModuleCustomerFile";
import { CustomerFilesPage } from "@/common/CustomerFilesPage";
import { CFCustomerPage } from "@/common/CustomerFilesPage/CFCustomerPage";
import { CFCreateOrEditPage } from "@/common/CustomerFilesPage/CFCreateOrEditPage";
import { ModuleAdmin } from "@/ModuleAdmin";
import { DevPage } from "@/common/DevPage";
import { DesktopDevLoggerPage } from "@/desktop/pages/DesktopDevLoggerPage";
import { DesktopDevRequestsPage } from "@/desktop/pages/DesktopDevRequestsPage";
import { C_CompanySettingsStartPage } from "@/common/companySettings/pages/C_CompanySettingsStartPage";
import { C_GeneralPage } from "@/common/companySettings/pages/C_GeneralPage";
import { C_ManageEmployeesPage } from "@/common/companySettings/pages/C_ManageEmployeesPage";
import { C_ManageWorkTypesPage } from "@/common/companySettings/pages/C_ManageWorkTypesPage";
import { C_ManageTargetHoursPage } from "@/common/companySettings/pages/C_ManageTargetHoursPage";
import { C_ManagePublicHolidaysPage } from "@/common/companySettings/pages/C_ManagePublicHolidaysPage";
import { C_ManageVacationDaysPage } from "@/common/companySettings/pages/C_ManageVacationDaysPage";
import { LogoutView } from "@/common/LogoutView";
import { NotFoundPage } from "@/common/NotFoundPage";
import { M_BottomNav } from "@/mobile/components/M_BottomNav";
import { M_ScrollToTop } from "@/mobile/components/M_ScrollToTop";
import { M_TopNav } from "@/mobile/components/M_TopNav";
import { ModuleCalendarToIncomeStatement } from "@/ModuleCalendarToIncomeStatement";
import { CISMainPage } from "@/ModuleCalendarToIncomeStatement/CISMainPage";

/** Add paths to hide the bottom mobile navbar */
const hideMobileNavOnPaths = [
    appPaths.login,
    appPaths.register,

    appPaths.setupPages.mainPage,
    appPaths.setupPages.createOrJoinCompany,
    appPaths.setupPages.introAfterCompanyCreation,

    appPaths.companyGeneral,
    appPaths.companyManageEmployees,
    appPaths.companyManageWorkTypes,
    appPaths.companyManageTargetHours,
    appPaths.companyManagePublicHolidays,
    appPaths.companyManageVacationDays,

    appPaths.logout,
];

export function AppRoutes() {
    return <AnimatePresence mode="wait" initial={false}>
        {isMobile && <M_ScrollToTop key="MobileScrollToTop"/>}
        <AllRoutes key="AllRoutes"/>
    </AnimatePresence>;
}

function AllRoutes() {
    const location = useLocation();

    useAutoRedirectToSetupPage();

    return <>
        {isMobile &&
            <M_TopNav key="MobileTopNav" hideNavOnPaths={hideMobileNavOnPaths}/>
        }

        <Routes location={location} key={location.pathname}>
            <Route path={appPaths.root} element={<LoadingSpinnerWithBackdrop disableTransparentBackground/>}/>

            <Route path={appPaths.login} element={<LoginPage/>}/>
            <Route path={appPaths.register} element={<RegisterPage/>}/>

            {/*<Route path={appPaths.tax.main} element={<TaxPage/>}/>*/}

            {/** Setup */}
            <Route path={appPaths.setupPages.mainPage} element={<SetupVerifyEmail/>}/>
            <Route path={appPaths.setupPages.createOrJoinCompany} element={<SetupCreateOrJoinCompany/>}/>
            <Route path={appPaths.setupPages.createCompany} element={<SetupCreateCompany/>}/>
            <Route path={appPaths.setupPages.companyCreated} element={<SetupCompanyCreatedPage/>}/>

            {/** Time-Booking */}
            <Route path={appPaths.timeBooking}
                   element={isMobile ? <MobileTimeBookingPage/> : <DesktopTimeBookingPage/>}/>
            <Route path={appPaths.vacation} element={isMobile ? <MobileVacationPage/> : <DesktopVacationPage/>}/>
            <Route path={appPaths.notes} element={isMobile ? <MobileNotesPage/> : <DesktopNotesPage/>}/>

            {/* Container */}
            <Route path={appPaths.drivingJobs} element={<DrivingJobOverviewPage/>}/>
            <Route path={appPaths.driverReports} element={<DriverReportsPage/>}/>
            <Route path={appPaths.containerContract.overview} element={<ContainerContractPage/>}/>
            <Route path={appPaths.containerContract.create} element={<CreateContainerContractPage/>}/>
            <Route path={appPaths.containerContract.edit} element={<EditContainerContractPage/>}/>
            <Route path={appPaths.deliveryNote + "/:drivingJobId/:contractNumber"} element={<DeliveryNotePage/>}/>
            <Route path={appPaths.containerLocation} element={<DesktopContainerLocationPage/>}/>

            {/* Customer-file */}
            <Route path={appPaths.cf.main} element={<ModuleCustomerFile/>}>
                <Route index element={<CustomerFilesPage/>}/>
                <Route path={appPaths.cf.customer} element={<CFCustomerPage/>}/>
                <Route path={appPaths.cf.create} element={<CFCreateOrEditPage mode="create"/>}/>
                <Route path={appPaths.cf.edit()} element={<CFCreateOrEditPage mode="edit"/>}/>
            </Route>

            {/* Calendar to income statement */}
            <Route path={appPaths.cis.main} element={<ModuleCalendarToIncomeStatement/>}>
                <Route index element={<CISMainPage/>}/>
            </Route>

            {/* Admin */}
            <Route path={appPaths.admin.main} element={<ModuleAdmin/>}/>

            {/* Dev */}
            <Route path={appPaths.dev} element={<DevPage/>}/>
            <Route path={appPaths.devLogger} element={<DesktopDevLoggerPage/>}/>
            <Route path={appPaths.devRequests} element={<DesktopDevRequestsPage/>}/>

            {/* Company settings */}
            <Route path={appPaths.companySettings} element={<C_CompanySettingsStartPage/>}/>
            <Route path={appPaths.companyGeneral} element={<C_GeneralPage/>}/>
            <Route path={appPaths.companyManageEmployees} element={<C_ManageEmployeesPage/>}/>
            <Route path={appPaths.companyManageWorkTypes} element={<C_ManageWorkTypesPage/>}/>
            <Route path={appPaths.companyManageTargetHours} element={<C_ManageTargetHoursPage/>}/>
            <Route path={appPaths.companyManagePublicHolidays} element={<C_ManagePublicHolidaysPage/>}/>
            <Route path={appPaths.companyManageVacationDays} element={<C_ManageVacationDaysPage/>}/>

            {/* Other */}
            <Route path={appPaths.logout} element={<LogoutView/>}/>
            <Route path="*" element={<NotFoundPage/>}/> {/* TODO: Make mobile to common page */}
        </Routes>

        {isMobile &&
            <M_BottomNav key="MobileBottomNav" hideNavOnPaths={hideMobileNavOnPaths}/>
        }
    </>;
}

import { useFirestore, useSigninCheck } from "reactfire";
import {
    CompaniesDocument,
    CompanyMemberDocument,
    ContainerContractDocument,
    ContractChange,
    CustomerFileCustomer,
    CustomerFileEntryDocument,
    CustomerFilesDocument,
    DeliveryNoteMessagesDocument,
    DriverReportDocument,
    DrivingJobsSortOrderDocument,
    NotificationsDocument,
    TimeBookingDocument,
    WorkTypeDocument
} from "tb-utils";
import { useCompanyPersistStore } from "@/stores/useCompanyPersistStore";
import { appLogger } from "@/helper/appLogger";
import {
    addDoc,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    FieldValue,
    runTransaction,
    serverTimestamp,
    setDoc,
    updateDoc,
    writeBatch
} from "firebase/firestore";

const FILE_NAME = "useFirestoreWrite.tsx";

export function useFirestoreWrite() {
    const db = useFirestore();
    const { data: userData } = useSigninCheck();
    const selectedCompanyId = useCompanyPersistStore(state => state.selectedCompanyId);

    const collRefs = {
        /** General */
        companies: () => collection(db, "companies"),
        // companyEventLogs: () => collection(docRefs.selectedCompany(), "company-event-logs"),
        companyMembers: () => collection(docRefs.selectedCompany(), "company-members"),
        notifications: () => collection(docRefs.selectedCompany(), "notifications"),
        pendingInvitations: () => collection(docRefs.selectedCompany(), "pending-invitations"),
        /** customer-file */
        customerFiles: () => collection(docRefs.selectedCompany(), "customer-files"),
        customerFileEntries: () => collection(docRefs.selectedCompany(), "customer-file-entries"),
        /** Container */
        containerContracts: () => collection(docRefs.selectedCompany(), "container-contracts"),
        deliveryNoteMessages: () => collection(docRefs.selectedCompany(), "delivery-note-messages"),
        drivingJobsSortOrders: () => collection(docRefs.selectedCompany(), "driving-jobs-sort-order"),
        driverReports: () => collection(docRefs.selectedCompany(), "driver-reports"),
        /** Time-booking */
        timeBookings: () => collection(docRefs.selectedCompany(), "time-bookings"),
        workTypes: () => collection(docRefs.selectedCompany(), "work-types")
    };
    const docRefs = {
        /** General */
        company: (companyId: string) => doc(db, "companies", companyId),
        selectedCompany: () => doc(db, "companies", selectedCompanyId || "-"),
        // companyEventLog: (logId?: string) => logId ?
        //     doc(collRefs.companyEventLogs(), logId) :
        //     doc(collRefs.companyEventLogs()),
        notification: (notificationId?: string) => notificationId ?
            doc(collRefs.notifications(), notificationId) :
            doc(collRefs.notifications()),
        companyMember: (memberId?: string) => memberId ?
            doc(collRefs.companyMembers(), memberId) :
            doc(collRefs.companyMembers()),
        pendingInvitation: (invId?: string) => invId ?
            doc(collRefs.pendingInvitations(), invId) :
            doc(collRefs.pendingInvitations()),
        /** customer-file */
        customerFile: (customerId?: string) => customerId ?
            doc(collRefs.customerFiles(), customerId) :
            doc(collRefs.customerFiles()),
        customerFileEntries: (customerId?: string) => customerId ?
            doc(collRefs.customerFileEntries(), customerId) :
            doc(collRefs.customerFileEntries()),
        /** Container */
        containerContract: (contractId: string) => doc(collRefs.containerContracts(), contractId),
        deliveryNoteMessage: (messageId?: string) => messageId ?
            doc(collRefs.deliveryNoteMessages(), messageId) :
            doc(collRefs.deliveryNoteMessages()),
        drivingJobsSortOrder: (docId?: string) => docId ?
            doc(collRefs.drivingJobsSortOrders(), docId) :
            doc(collRefs.drivingJobsSortOrders()),
        driverReport: (docId?: string) => docId ?
            doc(collRefs.driverReports(), docId) :
            doc(collRefs.driverReports()),
        /** Time-booking */
        timeBooking: (docId?: string) => docId ?
            doc(collRefs.timeBookings(), docId) :
            doc(collRefs.timeBookings()),
    };

    /**
     * Update company doc
     * @param data New company data
     */
    async function updateCompany(data: Partial<CompaniesDocument<Date>>) {
        return updateDoc(docRefs.selectedCompany(), data);
    }


    // /**
    //  * Create a company event log
    //  */
    // const createCompanyEventLog = {
    //     createDoc: async (type: EventLogsType, data: string | null, msg: string) => {
    //         return addDoc(collRefs.companyEventLogs(), {
    //             created_at: serverTimestamp(),
    //             event_type: type,
    //             data: data,
    //             message: msg,
    //             triggered_by: userData?.user?.uid || "",
    //         } as CompaniesEventLogs<Date | FieldValue>);
    //     },
    //
    //     deliveryNoteCompleted: (deliveryNote: DeliveryNote<Date>) => createCompanyEventLog.createDoc(
    //         300, deliveryNote.id, `${userData?.user?.displayName} hat den Auftrag LS ${deliveryNote.number} abgeschlossen.`
    //     ),
    //     deliveryNoteNotCompleted: (deliveryNote: DeliveryNote<Date>) => createCompanyEventLog.createDoc(
    //         301, deliveryNote.id, `${userData?.user?.displayName} hat den Auftrag LS ${deliveryNote.number} nicht abgeschlossen.`
    //     ),
    //     driverReportSubmitted: (deliveryNote: DeliveryNote<Date>) => createCompanyEventLog.createDoc(
    //         310, deliveryNote.id, `${userData?.user?.displayName} hat den Auftrag LS ${deliveryNote.number} nicht abgeschlossen.`
    //     ),
    // };

    /**
     * Update company-member
     * @param memberId member id
     * @param data Updated data
     */
    async function updateCompanyMember(memberId: string, data: Partial<CompanyMemberDocument<Date | FieldValue>>) {
        return updateDoc(docRefs.companyMember(memberId), data);
    }

    /**
     * Update company-member
     * @param id notification id
     * @param data Updated data
     */
    async function updateNotification(id: string, data: Partial<NotificationsDocument<Date>>) {
        return updateDoc(docRefs.notification(id), data);
    }

    /**
     * Delete pending invitation
     * @param invId invitation doc id
     */
    async function deletePendingInvitation(invId: string) {
        return deleteDoc(docRefs.pendingInvitation(invId));
    }

    /**
     * Create container-contract
     * @param contract The container contract data
     */
    async function createContainerContract(contract: ContainerContractDocument<Date | FieldValue>) {
        return addDoc(collRefs.containerContracts(), contract);
    }

    /**
     * Update container-contract data + create type 2 delivery-note-message(s) if any changes where made to the delivery-note
     * @param containerContract The current container contract
     * @param updateData Key-value pais to update
     */
    async function updateContainerContract(
        containerContract: ContainerContractDocument<Date>,
        updateData: Partial<ContainerContractDocument<Date>>,
    ) {
        if (!containerContract.doc_id) throw new Error("doc_id can't be undefined");

        const batch = writeBatch(db);

        /** Update container-contract (add to batch) */
        const contractRef = docRefs.containerContract(containerContract.doc_id);
        batch.update(contractRef, updateData);

        /** Get every change with old and new values */
        const contractChanges: ContractChange<Date>[] = Object.entries(updateData).map(value => {
            const key = value[0] as keyof ContainerContractDocument<FieldValue>;
            const oldValue = containerContract[key] as any;
            return {
                field: key,
                new: value[1],
                old: oldValue === undefined ? null : oldValue
            };
        });

        /** Create new delivery-note-message type 2 (add to batch) */
        const newType2Message = (changes: ContractChange<Date>[], deliveryNote: "c1" | "c2") => {
            const filtered = changes.filter(v => (
                !v.field.startsWith(deliveryNote === "c1" ? "c2" : "c1") || // remove other delivery-note
                v.field.startsWith("c1_da_") || // keep delivery address
                v.field.startsWith("c1_ba_")  // keep billing address
            ));

            /** Exit function if no changes apply for this delivery note */
            if (!filtered.length) return;

            const newMessageData: DeliveryNoteMessagesDocument<Date | FieldValue> = {
                text: "",
                imageUrl: null,
                author_id: userData?.user?.uid || "",
                type: 2,
                contract_changes: filtered,
                delivery_note_id: containerContract.doc_id + (deliveryNote === "c1" ? "/1" : "/2"),
                created_at: serverTimestamp()
            };
            batch.set(docRefs.deliveryNoteMessage(), newMessageData);
        };

        newType2Message(contractChanges, "c1")
        newType2Message(contractChanges, "c2");

        /** Commit all changes */
        return batch.commit();
    }

    async function deleteContainerContract(contractId: string) {
        return deleteDoc(docRefs.containerContract(contractId));
    }

    /**
     * Set delivery-note status to true and create log message
     */
    async function toggleDeliveryNoteStatus(
        containerContract: ContainerContractDocument<Date>,
        dnType: "c1" | "c2",
        updateTo: number
    ) {
        updateContainerContract(containerContract, {
            // Update if c1
            ...dnType === "c1" && {
                c1_status: updateTo,
                c1_status_changed_by: userData?.user?.uid,
            },
            // Update if c2
            ...dnType === "c2" && {
                c2_status: updateTo,
                c2_status_changed_by: userData?.user?.uid,
            },
        })
            // .then(() => {
            //     const dn = buildDeliveryNote(containerContract, dnType === "c1" ? 1 : 2);
            //     if (updateTo === 10) {
            //         createCompanyEventLog.deliveryNoteCompleted(dn)
            //             .catch(err => appLogger.error(FILE_NAME, "Error creating company event log document" + JSON.stringify(err)));
            //     } else {
            //         createCompanyEventLog.deliveryNoteNotCompleted(dn)
            //             .catch(err => appLogger.error(FILE_NAME, "Error creating company event log document" + JSON.stringify(err)));
            //     }
            // })
            .catch(err => appLogger.error(FILE_NAME, "Error updating container-contract" + JSON.stringify(err)));
    }

    /**
     * Create driving jobs sort order document
     * @param data The data
     */
    async function createDrivingJobsSortOrder(data: Partial<DrivingJobsSortOrderDocument<Date | FieldValue>>) {
        return addDoc(collRefs.drivingJobsSortOrders(), data);
    }

    /**
     * Update driving jobs sort order document
     * @param docId The firestore document id
     * @param data The new data
     */
    async function updateDrivingJobsSortOrder(docId: string, data: Partial<DrivingJobsSortOrderDocument<Date>>) {
        return updateDoc(docRefs.drivingJobsSortOrder(docId), data);
    }

    /**
     * Create driver report document
     * @param data Driving report data
     */
    async function createDriverReport(data: DriverReportDocument<Date | FieldValue>) {
        return addDoc(collRefs.driverReports(), data);
    }

    /**
     * Update driver report document
     * @param docId The firestore document id
     * @param data New driving report data
     */
    async function updateDriverReport(docId: string, data: Partial<DriverReportDocument<Date | FieldValue>>) {
        return updateDoc(docRefs.driverReport(docId), data);
    }

    /**
     * Delete driver report document
     * @param docId ID of document to delete
     */
    async function deleteDriverReport(docId: string) {
        return deleteDoc(docRefs.driverReport(docId));
    }

    /**
     * Customer file
     */
    const writeCustomerFile = {
        async setCustomerFile2(id: string) {
            return setDoc(docRefs.customerFile(id), {
                customers: [],
                created_at: serverTimestamp()
            } satisfies CustomerFilesDocument<FieldValue>);
        },

        async addCustomer(newCustomerFile: CustomerFileCustomer<Date | FieldValue>) {
            return updateDoc(docRefs.customerFile("customer_files_001"), {
                customers: arrayUnion(newCustomerFile),
            });
        },

        // async removeCustomer(customerId: string) {
        //     const customerFiles001 = await docRefs.customerFile("customer_files_001");
        //     return updateDoc(docRefs.customerFile("customer_files_001"), {
        //         customers:
        //     });
        // },

        async updateCustomer(customerId: string, newData: Partial<CustomerFileCustomer<Date | FieldValue>>) {
            try {
                await runTransaction(db, async t => {
                    const docName = "customer_files_001";
                    const customerFiles001 = await t.get(docRefs.customerFile(docName));
                    if (!customerFiles001.exists()) throw `Document ${docName} does not exist!`;

                    const tempCustomers: CustomerFileCustomer<FieldValue | Date>[] = customerFiles001.data().customers;
                    const replaceIndex = tempCustomers.findIndex(c => c.id === customerId);
                    if (replaceIndex < 0) throw "Customer could not be found and updated in customers array";
                    tempCustomers[replaceIndex] = {
                        ...tempCustomers[replaceIndex],
                        ...newData
                    };

                    t.update(docRefs.customerFile(docName), {
                        customers: tempCustomers
                    } satisfies Partial<CustomerFilesDocument<FieldValue | Date>>)
                });
            } catch (err) {
                console.error(err);
                throw "Transaction error: updateCustomer()"
            }
            // const tempCustomerData
            // return updateDoc(docRefs.customerFile("customer_files_001"), {
            //     customers: arrayUnion(newCustomerFile),
            // });
        },

        async changeCustomerVisibility(customerId: string, hidden: boolean) {
            await this.updateCustomer(customerId, { hidden: hidden });
        },

        async deleteCustomer(customerId: string) {
            await this.updateCustomer(customerId, { deleted: true });
        },
        async restoreCustomer(customerId: string) {
            await this.updateCustomer(customerId, { deleted: false });
        },

        async addEntry(entry: CustomerFileEntryDocument<Date | FieldValue>) {
            return addDoc(collRefs.customerFileEntries(), entry);
        },

        async updateEntry(entryId: string, partialEntry: Partial<CustomerFileEntryDocument<Date | FieldValue>>) {
            return updateDoc(docRefs.customerFileEntries(entryId), partialEntry);
        }
    };

    /**
     * Create time-booking
     */
    async function createTimeBooking(data: TimeBookingDocument<Date | FieldValue>) {
        return addDoc(collRefs.timeBookings(), data);
    }

    /**
     * Update time-booking
     * @param docId ID of document to update
     * @param data Data to update
     */
    async function updateTimeBooking(docId: string, data: Partial<TimeBookingDocument<Date | FieldValue>>) {
        return updateDoc(docRefs.timeBooking(docId), data);
    }

    /**
     * Create work-type
     */
    async function createWorkType(data: WorkTypeDocument<Date | FieldValue>) {
        return addDoc(collRefs.workTypes(), data);
    }

    return {
        /** General */
        updateCompany,
        // createCompanyEventLog,
        updateCompanyMember,
        updateNotification,
        deletePendingInvitation,

        /** Container */
        createContainerContract,
        updateContainerContract,
        deleteContainerContract,
        toggleDeliveryNoteStatus,

        createDrivingJobsSortOrder,
        updateDrivingJobsSortOrder,

        createDriverReport,
        updateDriverReport,
        deleteDriverReport,

        /** Customer-file */
        writeCustomerFile,

        /** Time-booking */
        createTimeBooking,
        updateTimeBooking,

        createWorkType,

        collRefs,
        docRefs
    };
}
